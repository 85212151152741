import { Stack } from '@mui/material';
import { FixtureComparisonRating } from '@/service/types';
import { ComparisonRatingForm } from './ComparisonRatingForm';

interface ComparisonRatingProps {
  comparisonRating: FixtureComparisonRating[];
}

export const ComparisonRating = ({
  comparisonRating,
}: ComparisonRatingProps) => (
  <Stack flexDirection='row' gap={4} sx={{ px: 4, pt: 2 }}>
    {comparisonRating.map((rating) => (
      <ComparisonRatingForm key={rating.id} rating={rating} />
    ))}
  </Stack>
);
