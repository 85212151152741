import useSWR from 'swr';
import { enqueueSnackbar } from 'notistack';
import { useContext } from 'react';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  SERVICE_ENDPOINT,
  fetchFromMTService,
} from '../fetcher/monitoringToolService';
import { HTTP_STATUS_CODE } from '../constants';
import { ApiError } from '../ApiError';
import { FixtureBasketStats } from '../types';

export const useFixtureExternalBasketStats = () => {
  const token = useAuthToken();
  const {
    state: { fixtureId, fixtureSummary },
  } = useContext(ScoringContext);

  const { data, error, mutate } = useSWR<FixtureBasketStats[], ApiError>(
    token && fixtureId && fixtureSummary?.collectionId
      ? {
          token,
          endpoint: SERVICE_ENDPOINT.Collections(
            fixtureSummary.collectionId,
            fixtureId,
          ).statistics.GET,
        }
      : null,
    fetchFromMTService,
    {
      revalidateOnFocus: false,
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (
          e.status !== HTTP_STATUS_CODE.NOT_FOUND &&
          e.status !== HTTP_STATUS_CODE.UNAUTHORIZED
        ) {
          return enqueueSnackbar(e.title, { variant: 'error' });
        }
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
    },
  );

  return {
    externalCollection: data,
    error,
    isLoading: data === undefined && !error,
    mutate,
  };
};
