import { useContext } from 'react';
import { ButtonsWrapper } from '@/components/StatsButtons/ButtonsWrapper';
import { StatsContext } from '@/contexts/stats/context';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { StatsButton } from '@/components/StatsButtons/common';
import { BUTTON_TYPES, StatsButtonType } from '@/contexts/stats/types';

interface TypeButtonsProps {
  gameStatsType?: boolean;
  americanFootball?: boolean;
  externalBasketball?: boolean;
}

export const TypeButtons = ({
  gameStatsType,
  americanFootball,
  externalBasketball = false,
}: TypeButtonsProps) => {
  const { typeButtons, changeActiveButton } = useContext(StatsContext);

  const filterTypeButtons = (typeButton: StatsButtonType) => {
    if (externalBasketball) {
      return typeButton.value === STATS_BUTTONS.TYPE.PLAYER;
    }
    if (!gameStatsType) {
      return typeButton.value !== STATS_BUTTONS.TYPE.GAME;
    }

    return true;
  };

  return (
    <ButtonsWrapper>
      {typeButtons.filter(filterTypeButtons).map((typeButton) => (
        <StatsButton
          isActive={typeButton.isActive || externalBasketball}
          variant={'outlined'}
          disableRipple={typeButton.isActive}
          onClick={() => changeActiveButton(typeButton, BUTTON_TYPES.TYPE)}
          key={typeButton.id}
          disabled={
            americanFootball && typeButton.id === STATS_BUTTONS.TYPE.PLAYER
          }
        >
          {typeButton.value}
        </StatsButton>
      ))}
    </ButtonsWrapper>
  );
};
