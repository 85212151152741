import { FC } from 'react';
import { TableCell, useTheme } from '@mui/material';
import { CustomTooltip } from '@/components/StatsCompareTable/StyledStatsComponents';
import { COMMON_STRING } from '@/constants/dictionary';
import { EXTERNAL_BASKET_HEADER_COLUMN } from './constants';

interface StaticsCellProps {
  internalValue: number;
  externalValue: number;
}

export const StatisticsCell: FC<StaticsCellProps> = ({
  internalValue,
  externalValue,
}) => {
  const isDifferent = internalValue !== externalValue;
  const theme = useTheme();
  return (
    <CustomTooltip
      title={isDifferent ? COMMON_STRING.EXTERNAL_DIFF_TOOLTIP : ''}
    >
      <TableCell
        sx={{
          width: `${
            100 / Object.values(EXTERNAL_BASKET_HEADER_COLUMN).length
          }%`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRight: `1px solid ${theme.palette.tableBorderColor}`,
          '&:last-of-type': {
            borderRight: 'none',
          },
          background: isDifferent ? `${theme.palette.error.main}52` : 'none',
        }}
      >
        {isDifferent ? `${externalValue} (${internalValue})` : internalValue}
      </TableCell>
    </CustomTooltip>
  );
};
