import React from 'react';
import { FixtureAdvancedStats } from '@/components/FixtureTabs/FixtureAdvancedStats';
import { FixtureBasketballStats } from '@/components/FixtureTabs/FixtureBasketballStats';
import { FixtureScoresheet } from '@/components/FixtureTabs/FixtureScoresheet';
import { SPORT_ID } from '@/service/constants';
import { FixtureAmericanFootballStats } from '@/components/FixtureTabs/FixtureAmericanFootballStats';
import { hasPermissionsToElement } from '@/components/PermissionsChecker/utils';
import { UserPermissions } from '@/service/types';
import { FixtureExternalStats } from '@/components/FixtureTabs/FixtureExternalStats';
import { FixtureExternalBasketStats } from '../ExternalBasketStats/FixtureExternalBasketStats';

export enum StatisticsSubPage {
  Advanced,
  Basketball,
  AmericanFootball,
  External,
  Scoresheet,
}

export interface StatisticsTabsType {
  id: StatisticsSubPage;
  name: string;
  component: JSX.Element;
  isVisible: boolean;
}

interface GenerateStatisticsTabsOptions {
  sportId?: SPORT_ID;
  permissions: UserPermissions;
  isExternalStats: boolean;
}

export const STATISTICS_TAB_NAME = {
  ADVANCED: 'Advanced',
  BASKETBALL: 'Basketball',
  AMERICAN_FOOTBALL: 'American Football',
  EXTERNAL: 'External',
  SCORESHEET: 'Scoresheet',
} as const;

export const generateStatisticsTabs = ({
  sportId,
  permissions,
  isExternalStats = false,
}: GenerateStatisticsTabsOptions) => {
  const tabs: StatisticsTabsType[] = [
    {
      id: StatisticsSubPage.Advanced,
      name: STATISTICS_TAB_NAME.ADVANCED,
      component: <FixtureAdvancedStats />,
      isVisible: hasPermissionsToElement(
        'FixturePage.AdvancedStats',
        permissions,
      ),
    },
    {
      id: StatisticsSubPage.Basketball,
      name: STATISTICS_TAB_NAME.BASKETBALL,
      component: <FixtureBasketballStats />,
      isVisible:
        sportId === SPORT_ID.BASKETBALL &&
        hasPermissionsToElement('FixturePage.BasketballStats', permissions),
    },
    {
      id: StatisticsSubPage.AmericanFootball,
      name: STATISTICS_TAB_NAME.AMERICAN_FOOTBALL,
      component: <FixtureAmericanFootballStats />,
      isVisible:
        sportId === SPORT_ID.AMERICAN_FOOTBALL &&
        hasPermissionsToElement(
          'FixturePage.AmericanFootballStats',
          permissions,
        ),
    },
    {
      id: StatisticsSubPage.External,
      name: STATISTICS_TAB_NAME.EXTERNAL,
      component: <FixtureExternalBasketStats />,
      isVisible:
        isExternalStats &&
        hasPermissionsToElement('FixturePage.QA.ExternalStats', permissions) &&
        sportId === SPORT_ID.BASKETBALL,
    },
    {
      id: StatisticsSubPage.External,
      name: STATISTICS_TAB_NAME.EXTERNAL,
      component: <FixtureExternalStats />,
      isVisible:
        hasPermissionsToElement('FixturePage.QA.ExternalStats', permissions) &&
        sportId === SPORT_ID.SOCCER,
    },
    {
      id: StatisticsSubPage.Scoresheet,
      name: STATISTICS_TAB_NAME.SCORESHEET,
      component: <FixtureScoresheet />,
      isVisible:
        sportId === SPORT_ID.BASKETBALL &&
        hasPermissionsToElement('FixturePage.Scoresheet', permissions),
    },
  ].filter((tab) => tab.isVisible);

  return tabs;
};
