import { Box } from '@mui/material';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import { Icon } from '@mdi/react';
import { mdiBilliardsRack, mdiHockeyPuck } from '@mdi/js';
import { FC } from 'react';
import { SPORT_ID, SPORT_NAME } from '@/service/constants';
import { CustomTooltip } from '@/components/StatsCompareTable/StyledStatsComponents';

type SportIconsProp = {
  sportId: number;
};

export const SPORT_ICON: Record<number, JSX.Element> = {
  [SPORT_ID.SOCCER]: <SportsSoccerIcon />,
  [SPORT_ID.BASKETBALL]: <SportsBasketballIcon />,
  [SPORT_ID.AMERICAN_FOOTBALL]: <SportsFootballIcon />,
  [SPORT_ID.ICE_HOCKEY]: (
    <Icon data-testid='hockey-icon' size={'1.5em'} path={mdiHockeyPuck} />
  ),
  [SPORT_ID.VOLLEYBALL]: <SportsVolleyballIcon />,
  [SPORT_ID.BEACH_VOLLEYBALL]: <SportsVolleyballIcon />,
  [SPORT_ID.SNOOKER]: (
    <Icon data-testid='snooker-icon' size={'1.7em'} path={mdiBilliardsRack} />
  ),
};

export const SportIcons: FC<SportIconsProp> = ({ sportId }) => {
  if (!sportId) return null;

  return (
    <Box display='flex' alignItems='center' justifyContent='center'>
      <CustomTooltip title={SPORT_NAME[sportId as keyof typeof SPORT_NAME]}>
        {SPORT_ICON[sportId]}
      </CustomTooltip>
    </Box>
  );
};
