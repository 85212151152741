import { Fragment, useContext, useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';
import { useSessionStorage } from 'usehooks-ts';
import { ScoringContext } from '@/contexts/scoring/context';

import {
  StyledMenuItem,
  StyledMenuList,
} from '@/components/common/StyledMenuItems';
import { getSport } from '@/service/utils/getSport';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import {
  generateStatisticsTabs,
  StatisticsSubPage,
} from '@/components/FixtureTabs/helpers/generateStatisticsTabs';
import { useFixtureExternalBasketStats } from '@/service/hooks/useFixtureExternalBasketStats';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';

const SS_STATISTICS_SUBTAB_KEY = 'MT-stats-subtabs-minder';

export const FixtureStatistics = () => {
  const { permissions } = useContext(RolePermissionsContext);
  const {
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const { externalCollection } = useFixtureExternalBasketStats();

  const [storedSubTab, setStoredSubTab] = useSessionStorage<StatisticsSubPage>(
    SS_STATISTICS_SUBTAB_KEY,
    StatisticsSubPage.Advanced,
  );

  const sport = getSport(fixtureConfig);

  const statisticsTabs = useMemo(
    () =>
      generateStatisticsTabs({
        sportId: sport && sport.id,
        permissions,
        isExternalStats: !!externalCollection && !!externalCollection.length,
      }),
    [sport, permissions, externalCollection],
  );

  const isTabAvailible = statisticsTabs.some(
    (subTab) => subTab.id === storedSubTab,
  );

  useEffect(() => {
    if (isTabAvailible) {
      return;
    }

    setStoredSubTab(statisticsTabs[0].id);
  }, [isTabAvailible, setStoredSubTab, statisticsTabs]);

  const isSubPageSelected = (subPage: StatisticsSubPage) =>
    subPage === storedSubTab;

  return (
    <PermissionsChecker name='FixturePage.Statistics'>
      <Stack direction='row' display='flex' flex='1'>
        <StyledMenuList>
          {statisticsTabs.map((statisticsTab) => (
            <StyledMenuItem
              key={`stats-tabs-${statisticsTab.id}`}
              selected={isSubPageSelected(statisticsTab.id)}
              onClick={() => setStoredSubTab(statisticsTab.id)}
            >
              {statisticsTab.name}
            </StyledMenuItem>
          ))}
        </StyledMenuList>
        {statisticsTabs.map(
          (statisticsTab) =>
            isSubPageSelected(statisticsTab.id) && (
              <Fragment key={`stats-components-${statisticsTab.id}`}>
                {statisticsTab.component}
              </Fragment>
            ),
        )}
      </Stack>
    </PermissionsChecker>
  );
};
