import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { FixtureBasketStats, PlayerBasketStatistics } from '@/service/types';
import { FilterExternalBasketTableProps } from '../types';

export const deductStats = (
  currentStats: PlayerBasketStatistics,
  previousStats: PlayerBasketStatistics,
): PlayerBasketStatistics => {
  return {
    ...currentStats,
    externalStatistics: {
      assists: Math.max(
        currentStats.externalStatistics.assists -
          previousStats.externalStatistics.assists,
        0,
      ),
      pointsMade: Math.max(
        currentStats.externalStatistics.pointsMade -
          previousStats.externalStatistics.pointsMade,
        0,
      ),
      rebounds: Math.max(
        currentStats.externalStatistics.rebounds -
          previousStats.externalStatistics.rebounds,
        0,
      ),
    },
    internalStatistics: {
      assists: Math.max(
        currentStats.internalStatistics.assists -
          previousStats.internalStatistics.assists,
        0,
      ),
      pointsMade: Math.max(
        currentStats.internalStatistics.pointsMade -
          previousStats.internalStatistics.pointsMade,
        0,
      ),
      rebounds: Math.max(
        currentStats.internalStatistics.rebounds -
          previousStats.internalStatistics.rebounds,
        0,
      ),
    },
  };
};

export const findLatestPeriodStats = (
  statistics: FixtureBasketStats[],
): FixtureBasketStats | undefined => {
  return statistics
    .filter(
      (stat) => stat.period === Math.max(...statistics.map((s) => s.period)),
    )
    .sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
    )[0];
};

export const findStatsByPeriod = (
  statistics: FixtureBasketStats[],
  period: number,
): FixtureBasketStats | undefined => {
  return statistics
    .filter((stat) => stat.period === period)
    .sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
    )[0];
};

export const calculatePeriodDifference = (
  currentStats: FixtureBasketStats,
  previousStats: FixtureBasketStats,
  activeTeam?: string,
): PlayerBasketStatistics[] => {
  return currentStats.playerStatistics
    .filter((player) => player.teamId === activeTeam)
    .map((currentPlayer) => {
      const previousPlayer = previousStats.playerStatistics.find(
        (player) => player.playerId === currentPlayer.playerId,
      );

      return previousPlayer
        ? deductStats(currentPlayer, previousPlayer)
        : currentPlayer;
    });
};

export const filterExternalBasketStats = ({
  activeQuarter,
  activeTeam,
  statistics,
}: FilterExternalBasketTableProps) => {
  if (!statistics || !statistics.length) {
    return [];
  }

  const isAllQuarter = activeQuarter === STATS_BUTTONS.QUARTER.ALL.LABEL;

  const lastPeriodStats = findLatestPeriodStats(statistics);
  if (!lastPeriodStats) return [];

  if (isAllQuarter) {
    return lastPeriodStats.playerStatistics
      .filter((player) => player.teamId === activeTeam)
      .sort((a, b) => Number(a.shirtNumber) - Number(b.shirtNumber));
  }

  const activePeriod = Number(activeQuarter);
  const currentStats = findStatsByPeriod(statistics, activePeriod);
  const previousStats = findStatsByPeriod(statistics, activePeriod - 1);

  if (!currentStats) return [];

  if (activePeriod === 1 || !previousStats) {
    return currentStats.playerStatistics
      .filter((player) => player.teamId === activeTeam)
      .sort((a, b) => Number(a.shirtNumber) - Number(b.shirtNumber));
  }

  const result = calculatePeriodDifference(
    currentStats,
    previousStats,
    activeTeam,
  );

  return result.sort((a, b) => Number(a.shirtNumber) - Number(b.shirtNumber));
};
