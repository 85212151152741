export const EXTERNAL_BASKET_HEADER_COLUMN = {
  NAME: 'Name',
  PTS_MADE: 'PTS made',
  REBOUNDS: 'Rebounds',
  ASSIST: 'Assist',
};

export const externalBasketColumnCount = Object.values(
  EXTERNAL_BASKET_HEADER_COLUMN,
).length;
