import { TableHead, TableRow } from '@mui/material';
import { StatsTableHeaderActionCell } from '@/components/StatsTableCells/StatsTableCells';
import {
  externalBasketColumnCount,
  EXTERNAL_BASKET_HEADER_COLUMN,
} from './constants';

export const TableHeader = () => (
  <TableHead
    sx={{
      display: 'flex',
      width: '50%',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);',
    }}
  >
    <TableRow sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      {Object.values(EXTERNAL_BASKET_HEADER_COLUMN).map((columnName) => (
        <StatsTableHeaderActionCell
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
          key={columnName}
          width={`${100 / externalBasketColumnCount}%`}
        >
          {columnName}
        </StatsTableHeaderActionCell>
      ))}
    </TableRow>
  </TableHead>
);
