import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Slider, Stack, Typography, useTheme } from '@mui/material';
import { FixtureScorerRating } from '@/service/types';
import {
  ComparisonBox,
  NumberTextField,
} from '@/components/ComparisonRating/common';
import {
  calculateOverallScore,
  convertServiceLevelToString,
} from '@/components/ComparisonRating/utils';
import { COMMON_STRING } from '@/constants/dictionary';
import { COMPARISON_RATING_LABEL } from './ComparisonRatingForm';

interface ComparisonRatingScoreProps {
  newRating: FixtureScorerRating | undefined;
  setNewRating: Dispatch<SetStateAction<FixtureScorerRating | undefined>>;
}

export const ComparisonRatingScore = ({
  newRating,
  setNewRating,
}: ComparisonRatingScoreProps) => {
  const theme = useTheme();
  const [quantityScore, setQuantityScore] = useState<number>(
    newRating?.latencyScore || 100,
  );
  const [latencyScore, setLatencyScore] = useState<number>(
    newRating?.quantityScore || 100,
  );

  useEffect(() => {
    setNewRating({
      ...newRating,
      quantityScore: quantityScore,
      latencyScore: latencyScore,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityScore, latencyScore]);

  useEffect(() => {
    if (newRating) {
      setNewRating(calculateOverallScore(newRating));
    }
    if (
      newRating?.quantityScore !== undefined &&
      newRating?.latencyScore !== undefined
    ) {
      setLatencyScore(newRating.latencyScore);
      setQuantityScore(newRating.quantityScore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRating?.quantityScore, newRating?.latencyScore]);

  const ratingInputHandler = (
    ratingType: 'latencyScore' | 'quantityScore',
    value: number,
  ) => {
    if (value > 100 || value < 0) return;

    switch (ratingType) {
      case 'latencyScore':
        return setLatencyScore(value);
      case 'quantityScore':
        return setQuantityScore(value);
      default:
        return;
    }
  };

  return (
    <ComparisonBox>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        {COMMON_STRING.QUANTITY_SCORE}
        <Slider
          aria-label={COMPARISON_RATING_LABEL.QUANTITY_SCORE_SLIDER}
          value={quantityScore}
          onChange={(event, newValue) => setQuantityScore(newValue as number)}
          sx={{ width: '50%' }}
        />
        <NumberTextField
          type='number'
          value={quantityScore}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            ratingInputHandler('quantityScore', Number(event.target.value))
          }
          key='quantity-score'
          InputProps={{
            inputProps: {
              max: 100,
              min: 0,
              'aria-label': COMPARISON_RATING_LABEL.QUANTITY_SCORE_INPUT,
            },
          }}
        />
      </Stack>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        {COMMON_STRING.LATENCY_SCORE}
        <Slider
          aria-label={COMPARISON_RATING_LABEL.LATENCY_SCORE_SLIDER}
          value={latencyScore}
          onChange={(event, newValue) => setLatencyScore(newValue as number)}
          sx={{ width: '50%' }}
        />
        <NumberTextField
          type='number'
          value={latencyScore}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            ratingInputHandler('latencyScore', Number(event.target.value))
          }
          key='latency-score'
          InputProps={{
            inputProps: {
              max: 100,
              min: 0,
              'aria-label': COMPARISON_RATING_LABEL.LATENCY_SCORE_INPUT,
            },
          }}
        />
      </Stack>
      <Stack flexDirection='row' gap={theme.spacing(0.5)}>
        <Typography>{COMMON_STRING.OVERALL_SCORE}</Typography>
        <Typography fontWeight='bold'>{newRating?.overallScore}</Typography>
        <Typography>{COMMON_STRING.POINTS}</Typography>
        <Typography fontWeight='bold'>
          ({convertServiceLevelToString(newRating?.serviceLevel)})
        </Typography>
      </Stack>
    </ComparisonBox>
  );
};
