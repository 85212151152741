import { useContext } from 'react';
import { Stack } from '@mui/material';
import { FixturePostMatchCheckStatistics } from '@/service/types';
import { getTeamColor } from '@/components/ActionsTable/utils';
import { ComparisonTableCell } from '@/components/ComparisonTable/ComparisonTableCell';
import { ScoringContext } from '@/contexts/scoring/context';
import { COMPARISON_COLUMNS } from '@/components/ComparisonTable/constants';
import { COMMON_STRING } from '@/constants/dictionary';
import { ComparisonTableStickyCell } from './ComparisonTableStickyCell';

export type ComparisonTableCellRowsProps = {
  statistics: FixturePostMatchCheckStatistics | undefined;
};

const getRowBackgroundStyle = (teamColor?: string) => {
  if (!teamColor) return {};
  return {
    backgroundImage: `linear-gradient(90deg, rgba(${teamColor}, 1) 4px, transparent 4px)`,
  };
};

export const ComparisonTableCellRow = ({
  statistics,
}: ComparisonTableCellRowsProps) => {
  const {
    state: { fixtureSummary },
  } = useContext(ScoringContext);
  return (
    <Stack
      direction='row'
      data-action-id={statistics?.statsType}
      sx={{
        clipPath: 'inset(0)',
        alignItems: 'stretch',
        '&:hover': {
          background: (theme) => theme.palette.action.hover,
        },
        '&>div': {
          borderRight: (theme) => `1px solid ${theme.palette.tableBorderColor}`,
          borderBottom: (theme) =>
            `1px solid ${theme.palette.tableBorderColor}`,
        },
      }}
    >
      <ComparisonTableStickyCell
        width={COMPARISON_COLUMNS.SOURCE.width}
        columnName={COMPARISON_COLUMNS.SOURCE.name}
        label={statistics?.statsType}
        subLabel={statistics?.teamName || COMMON_STRING.NOT_AVAILABLE}
        padding={1}
        textAlign='left'
        position='sticky'
        left={0}
        style={{
          ...getRowBackgroundStyle(
            getTeamColor(statistics?.teamId, fixtureSummary),
          ),
        }}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.GOAL.width}
        columnName={COMPARISON_COLUMNS.GOAL.name}
        overallValue={statistics?.goal?.overallValue}
        supervisorsValue={statistics?.goal?.supervisorsValue}
        isMismatch={statistics?.goal?.isMismatch}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.GOAL_DISALLOWED.width}
        columnName={COMPARISON_COLUMNS.GOAL_DISALLOWED.name}
        overallValue={statistics?.goalDisallowed?.overallValue}
        supervisorsValue={statistics?.goalDisallowed?.supervisorsValue}
        isMismatch={statistics?.goalDisallowed?.isMismatch}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.YELLOW_CARD.width}
        columnName={COMPARISON_COLUMNS.YELLOW_CARD.name}
        overallValue={statistics?.yellowCard?.overallValue}
        supervisorsValue={statistics?.yellowCard?.supervisorsValue}
        isMismatch={statistics?.yellowCard?.isMismatch}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.RED_CARD.width}
        columnName={COMPARISON_COLUMNS.RED_CARD.name}
        overallValue={statistics?.redCard?.overallValue}
        supervisorsValue={statistics?.redCard?.supervisorsValue}
        isMismatch={statistics?.redCard?.isMismatch}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.ATTACK.width}
        columnName={COMPARISON_COLUMNS.ATTACK.name}
        overallValue={statistics?.attack?.overallValue}
        supervisorsValue={statistics?.attack?.supervisorsValue}
        isMismatch={statistics?.attack?.isMismatch}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.DANGEROUS_ATTACK.width}
        columnName={COMPARISON_COLUMNS.DANGEROUS_ATTACK.name}
        overallValue={statistics?.dangerousAttack?.overallValue}
        supervisorsValue={statistics?.dangerousAttack?.supervisorsValue}
        isMismatch={statistics?.dangerousAttack?.isMismatch}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.PENALTY_AWARDED.width}
        columnName={COMPARISON_COLUMNS.PENALTY_AWARDED.name}
        overallValue={statistics?.penaltyAwarded?.overallValue}
        supervisorsValue={statistics?.penaltyAwarded?.supervisorsValue}
        isMismatch={statistics?.penaltyAwarded?.isMismatch}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.PENALTY_MISSED.width}
        columnName={COMPARISON_COLUMNS.PENALTY_MISSED.name}
        overallValue={statistics?.penaltyMissed?.overallValue}
        supervisorsValue={statistics?.penaltyMissed?.supervisorsValue}
        isMismatch={statistics?.penaltyMissed?.isMismatch}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.FREE_KICK.width}
        columnName={COMPARISON_COLUMNS.FREE_KICK.name}
        overallValue={statistics?.freeKick?.overallValue}
        supervisorsValue={statistics?.freeKick?.supervisorsValue}
        isMismatch={statistics?.freeKick?.isMismatch}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.SHOT_ON_TARGET.width}
        columnName={COMPARISON_COLUMNS.SHOT_ON_TARGET.name}
        overallValue={statistics?.shotOnTarget?.overallValue}
        supervisorsValue={statistics?.shotOnTarget?.supervisorsValue}
        isMismatch={statistics?.shotOnTarget?.isMismatch}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.SHOT_OFF_TARGET.width}
        columnName={COMPARISON_COLUMNS.SHOT_OFF_TARGET.name}
        overallValue={statistics?.shotOffTarget?.overallValue}
        supervisorsValue={statistics?.shotOffTarget?.supervisorsValue}
        isMismatch={statistics?.shotOffTarget?.isMismatch}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.CORNER.width}
        columnName={COMPARISON_COLUMNS.CORNER.name}
        overallValue={statistics?.corner?.overallValue}
        supervisorsValue={statistics?.corner?.supervisorsValue}
        isMismatch={statistics?.corner?.isMismatch}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.SHOT_BLOCKED.width}
        columnName={COMPARISON_COLUMNS.SHOT_BLOCKED.name}
        overallValue={statistics?.blocked?.overallValue}
        supervisorsValue={statistics?.blocked?.supervisorsValue}
        isMismatch={statistics?.blocked?.isMismatch}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.OFFSIDE.width}
        columnName={COMPARISON_COLUMNS.OFFSIDE.name}
        overallValue={statistics?.offside?.overallValue}
        supervisorsValue={statistics?.offside?.supervisorsValue}
        isMismatch={statistics?.offside?.isMismatch}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.THROW_IN.width}
        columnName={COMPARISON_COLUMNS.THROW_IN.name}
        overallValue={statistics?.throwIn?.overallValue}
        supervisorsValue={statistics?.throwIn?.supervisorsValue}
        isMismatch={statistics?.throwIn?.isMismatch}
      />
    </Stack>
  );
};
