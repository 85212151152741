import { MatchSummaryTeam, Period } from '@/service/types';
import { ALL_QUARTER_BUTTON, STATS_BUTTONS } from '@/contexts/stats/constants';
import { StatsButtonType } from '@/contexts/stats/types';
import { SPORT_ID } from '@/service/constants';

type TeamDataType = {
  id?: MatchSummaryTeam['id'];
  name?: MatchSummaryTeam['teamName'];
};

type TeamButtonsType = {
  homeTeam: TeamDataType;
  awayTeam: TeamDataType;
};

export const generateStatsTypeButtons = (sportId: number) => {
  switch (sportId) {
    case SPORT_ID.BASKETBALL:
      return generateBasketballStatsTypeButtons();
    case SPORT_ID.AMERICAN_FOOTBALL:
      return generateAmericanFootballStatsTypeButtons();
    default:
      return;
  }
};

export const generateQuarterButtons = (periods: Period[], sportId: number) => {
  const namedQuarterButtons: StatsButtonType[] = periods.map((period) => {
    return {
      id: period.label,
      isActive: false,
      value: period.periodNumber,
    };
  });

  return [ALL_QUARTER_BUTTON, ...namedQuarterButtons];
};

export const generateTypeButtons = (sportId: number): StatsButtonType[] => {
  const typeButtons = [
    {
      value: STATS_BUTTONS.TYPE.PLAYER,
      id: STATS_BUTTONS.TYPE.PLAYER,
      isActive:
        sportId !== SPORT_ID.SNOOKER && sportId !== SPORT_ID.AMERICAN_FOOTBALL,
    },
    {
      value: STATS_BUTTONS.TYPE.TEAM,
      id: STATS_BUTTONS.TYPE.TEAM,
      isActive:
        sportId === SPORT_ID.SNOOKER || sportId === SPORT_ID.AMERICAN_FOOTBALL,
    },
    {
      value: STATS_BUTTONS.TYPE.GAME,
      id: STATS_BUTTONS.TYPE.GAME,
      isActive: false,
    },
  ];
  return sportId !== SPORT_ID.AMERICAN_FOOTBALL
    ? typeButtons
    : typeButtons.reverse();
};

export const generateTeamButtons = ({
  homeTeam,
  awayTeam,
}: TeamButtonsType) => {
  return [
    {
      id: homeTeam?.id,
      value: homeTeam?.name,
      isActive: true,
    },
    {
      id: awayTeam?.id,
      value: awayTeam?.name,
      isActive: false,
    },
  ];
};

export const generateBasketballStatsTypeButtons = () =>
  [
    {
      value: STATS_BUTTONS.STATS.GAME,
      id: STATS_BUTTONS.STATS.GAME,
      isActive: false,
    },
    {
      value: STATS_BUTTONS.STATS.BOX_SCORE,
      id: STATS_BUTTONS.STATS.BOX_SCORE,
      isActive: true,
    },
    {
      value: STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
      id: STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
      isActive: false,
    },
    {
      value: STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
      id: STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
      isActive: false,
    },
    {
      value: STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
      id: STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
      isActive: false,
    },
    {
      value: STATS_BUTTONS.STATS.ZONE_STATS,
      id: STATS_BUTTONS.STATS.ZONE_STATS,
      isActive: false,
    },
  ] as StatsButtonType[];

export const generateAmericanFootballStatsTypeButtons = () =>
  [
    {
      value: STATS_BUTTONS.STATS.PASSING,
      id: STATS_BUTTONS.STATS.PASSING,
      isActive: true,
    },
    {
      value: STATS_BUTTONS.STATS.RUSHING,
      id: STATS_BUTTONS.STATS.RUSHING,
      isActive: false,
    },
    {
      value: STATS_BUTTONS.STATS.RECEIVING,
      id: STATS_BUTTONS.STATS.RECEIVING,
      isActive: false,
    },
    {
      value: STATS_BUTTONS.STATS.DEFENSIVE,
      id: STATS_BUTTONS.STATS.DEFENSIVE,
      isActive: false,
    },
    {
      value: STATS_BUTTONS.STATS.SPECIAL_TEAMS_PUNTING,
      id: STATS_BUTTONS.STATS.SPECIAL_TEAMS_PUNTING,
      isActive: false,
    },
    {
      value: STATS_BUTTONS.STATS.SPECIAL_TEAMS_ALL_RETURNS,
      id: STATS_BUTTONS.STATS.SPECIAL_TEAMS_ALL_RETURNS,
      isActive: false,
    },
    {
      value: STATS_BUTTONS.STATS.SPECIAL_TEAMS_KICKOFFS,
      id: STATS_BUTTONS.STATS.SPECIAL_TEAMS_KICKOFFS,
      isActive: false,
    },
  ] as StatsButtonType[];
