import envVariables from '@/envVariables';
import { API_METHOD } from '../constants';
import { ApiError } from '../ApiError';

export enum FILTER_ENDPOINTS_TYPE_KEYS {
  competitions = 'competitions',
  telegramAdmins = 'telegramAdmins',
  supervisors = 'supervisors',
  sports = 'sports',
  workstations = 'workstations',
  ranks = 'ranks',
}

export const FILTERS_ENDPOINTS_NAMES = {
  [FILTER_ENDPOINTS_TYPE_KEYS.competitions]: 'competitions',
  [FILTER_ENDPOINTS_TYPE_KEYS.telegramAdmins]: 'telegram-admins',
  [FILTER_ENDPOINTS_TYPE_KEYS.supervisors]: 'supervisors',
  [FILTER_ENDPOINTS_TYPE_KEYS.sports]: 'sports',
  [FILTER_ENDPOINTS_TYPE_KEYS.workstations]: 'workstations',
  [FILTER_ENDPOINTS_TYPE_KEYS.ranks]: 'ranks',
};

export const SERVICE_ENDPOINT = {
  Fixture: (fixtureId?: string) => {
    const category = '/Fixture';
    return {
      fixtureConfig: {
        GET: `${category}/${fixtureId}/config`,
      },
      postMatchCheck: { GET: `${category}/${fixtureId}/post-match-qa` },
      reasonOptions: { GET: `/get-reason-options` },
      fixtureCollectorsData: {
        GET: `${category}/${fixtureId}/get-data-collectors-details`,
      },
      isMyFixture: {
        GET: `${category}/${fixtureId}/is-my-fixture`,
      },
      notificationsConfiguration: {
        GET: `${category}/${fixtureId}/get-notifications-configuration`,
      },
      slaChecklist: {
        GET: `${category}/${fixtureId}/get-qa-sla-checklist`,
      },
    };
  },
  SlaBreach: (fixtureId?: string) => {
    const category = '/SlaBreach';
    return {
      slaBreachRules: {
        GET: `${category}/rules`,
      },
      latencyEvaluation: {
        GET: `${category}/${fixtureId}/action-latency-evaluations`,
      },
      slaBreachAllComments: {
        GET: `${category}/${fixtureId}/action-latency-evaluation-comment`,
      },
    };
  },
  ScoringRate: (fixtureId?: string) => {
    const category = '/ScoringRate';
    return {
      scoringRate: {
        GET: `${category}/${fixtureId}/fixture-resources`,
        POST: category,
        PUT: category,
        DELETE: category,
      },
    };
  },
  Streaming: (fixtureId?: string) => {
    const category = '/Streaming';
    return {
      streaming: {
        GET: `${category}/${fixtureId}/url`,
      },
    };
  },

  FixtureAction: (fixtureId: string) => {
    const category = '/FixtureAction';
    return {
      generateFakeAction: {
        GET: `${category}/${fixtureId}/generate-fake-action`,
      },
    };
  },
  FixtureActionComment: '/FixtureActionComment',
  FixtureChecklist: '/FixtureCheckList',
  UserAuthorization: {
    myRoles: {
      GET: '/User/my-roles',
    },
    myPermissions: {
      GET: '/User/my-permissions',
    },
  },
  Collections: (collectionId: string, fixtureId: string) => {
    const category = '/Collections';
    return {
      statistics: {
        GET: `${category}/${collectionId}/${fixtureId}/statistics`,
      },
      statisticsOnDemand: {
        GET: `${category}/${collectionId}/${fixtureId}/statistics-on-demand`,
      },
    };
  },
  FixtureActions: () => {
    const category = '/FixtureActions';
    return {
      ActionTypes: { GET: `${category}/get-action-types` },
      SendTypes: { GET: `${category}/get-send-types` },
      SupportedSports: { GET: `${category}/get-supported-sports` },
      SwitchMarketActionTypesConfig: {
        GET: `${category}/get-switch-market-action-types-config`,
      },
    };
  },
  FixturesWithIncidents: () => {
    const category = '/SuperAdminView';
    return {
      CollectionsIssuesSummary: {
        GET: `${category}/collections-issues-summary`,
      },
      AssignToIssues: {
        POST: `${category}/assign-to-issues`,
      },
      UnAssignFromIssues: {
        POST: `${category}/un-assign-from-issues`,
      },
    };
  },
  SuperAdminFilters: () => {
    const category = '/SuperAdminView';
    return {
      [FILTER_ENDPOINTS_TYPE_KEYS.competitions]: {
        GET: `${category}/${FILTERS_ENDPOINTS_NAMES.competitions}`,
      },
      [FILTER_ENDPOINTS_TYPE_KEYS.telegramAdmins]: {
        GET: `${category}/${FILTERS_ENDPOINTS_NAMES.telegramAdmins}`,
      },
      [FILTER_ENDPOINTS_TYPE_KEYS.supervisors]: {
        GET: `${category}/${FILTERS_ENDPOINTS_NAMES.supervisors}`,
      },
      [FILTER_ENDPOINTS_TYPE_KEYS.sports]: {
        GET: `${category}/${FILTERS_ENDPOINTS_NAMES.sports}`,
      },
      [FILTER_ENDPOINTS_TYPE_KEYS.workstations]: {
        GET: `${category}/${FILTERS_ENDPOINTS_NAMES.workstations}`,
      },
      [FILTER_ENDPOINTS_TYPE_KEYS.ranks]: {
        GET: `/collection-rank-types`,
      },
    };
  },
} as const;

export type ServiceEndpoint =
  (typeof SERVICE_ENDPOINT)[keyof typeof SERVICE_ENDPOINT];

export const makeUrlWithParams = (
  url: string,
  params?: Record<string, string>,
) => {
  if (!params) return new URL(url);
  const searchParams = new URLSearchParams(params).toString();
  if (!searchParams) return new URL(url);
  return new URL(`${url}?${searchParams}`);
};

export interface FetchFromMTServiceOptions {
  token: string;
  endpoint: string;
  queryParams?: Record<string, string>;
}

export const fetchFromMTService = async ({
  token,
  endpoint,
  queryParams,
}: FetchFromMTServiceOptions) => {
  const url = makeUrlWithParams(envVariables.mtApiUrl + endpoint, queryParams);
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: API_METHOD.GET,
  });

  if (!res.ok && res.status !== 210) {
    throw await ApiError.parseFromResponse(res);
  }
  const contentType = res.headers.get('content-type');
  if (!contentType || !contentType.includes('application/json'))
    return res.text();
  return res.json();
};
