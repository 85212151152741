import { Divider } from '@mui/material';
import { StyledStatisticsTabWrapper } from '@/components/common/StyledStatisticsTabWrapper';
import { PermissionsChecker } from '@/components/PermissionsChecker/PermissionsChecker';
import { ExternalBasketStatsButtons } from '@/components/StatsButtons/ExternalBasketStatsButtons';
import { FixtureExternalBasketTable } from './FixtureExternalBasketTable';

export const FixtureExternalBasketStats = () => {
  return (
    <PermissionsChecker name='FixturePage.AdvancedStats'>
      <StyledStatisticsTabWrapper>
        <ExternalBasketStatsButtons />
        <Divider />
        <FixtureExternalBasketTable />
      </StyledStatisticsTabWrapper>
    </PermissionsChecker>
  );
};
