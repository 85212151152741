import { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ScoringContext } from '@/contexts/scoring/context';
import { FixtureComparisonRating, FixtureScorerRating } from '@/service/types';
import { ComparisonRatingButtons } from '@/components/ComparisonRating/ComparisonRatingButtons';
import { ComparisonRatingComment } from '@/components/ComparisonRating/ComparisonRatingComment';
import { ComparisonRatingScore } from '@/components/ComparisonRating/ComparisonRatingScore';
import { ComparisonRatingInfo } from '@/components/ComparisonRating/ComparisonRatingInfo';
import { AddRatingButton } from '@/components/ComparisonRating/common';
import {
  calculateOverallScore,
  generateDefaultScorerRating,
} from '@/components/ComparisonRating/utils';
import { COMMON_STRING } from '@/constants/dictionary';

interface ComparisonRatingFormProps {
  rating: FixtureComparisonRating;
}

export const COMPARISON_RATING_LABEL = {
  ADD_NEW_RATING_BUTTON: 'add-new-rating-button',
  QUANTITY_SCORE_INPUT: 'quantity-score-input',
  LATENCY_SCORE_INPUT: 'latency-score-input',
  QUANTITY_SCORE_SLIDER: 'quantity-score-slider',
  LATENCY_SCORE_SLIDER: 'latency-score-slider',
};

export const ComparisonRatingForm = ({ rating }: ComparisonRatingFormProps) => {
  const {
    state: { fixtureId },
  } = useContext(ScoringContext);
  const [scorerRating, setScorerRating] = useState<
    FixtureScorerRating | undefined
  >();

  const personalisedScorerRating = useMemo(() => {
    const personalisedRating = rating?.user?.scorerRatings.find(
      (rating) => rating.fixtureId === fixtureId,
    );

    return personalisedRating
      ? {
          ...personalisedRating,
          id: rating.user.id,
        }
      : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating]);

  useEffect(() => {
    if (personalisedScorerRating) {
      setScorerRating(calculateOverallScore(personalisedScorerRating));
    } else setScorerRating(undefined);
  }, [personalisedScorerRating]);

  return (
    <Box
      sx={{
        width: 555,
      }}
    >
      <Paper elevation={1}>
        <ComparisonRatingInfo comparisonRating={rating} />
        <Stack position='relative'>
          <ComparisonRatingScore
            newRating={scorerRating}
            setNewRating={setScorerRating}
          />
          <ComparisonRatingComment
            newRating={scorerRating}
            setNewRating={setScorerRating}
          />
          <ComparisonRatingButtons
            userId={rating.user.id}
            oktaId={rating.user.oktaId}
            rating={personalisedScorerRating}
            newRating={scorerRating}
            setNewRating={setScorerRating}
          />
          {!scorerRating && (
            <AddRatingButton
              aria-label={COMPARISON_RATING_LABEL.ADD_NEW_RATING_BUTTON}
              aria-hidden={!!scorerRating}
              variant='text'
              onClick={() =>
                setScorerRating(
                  calculateOverallScore(
                    generateDefaultScorerRating(fixtureId, rating.user),
                  ),
                )
              }
            >
              <Stack direction='row' alignItems='center' alignContent='center'>
                <AddIcon />
                <Typography
                  fontSize='large'
                  sx={{
                    ml: 1,
                  }}
                >
                  {COMMON_STRING.ADD_RATING}
                </Typography>
              </Stack>
            </AddRatingButton>
          )}
        </Stack>
      </Paper>
    </Box>
  );
};
