import { useContext, useEffect, useMemo, useState } from 'react';
import { CircularProgress, TableBody, TableContainer } from '@mui/material';
import { useFixtureExternalBasketStats } from '@/service/hooks/useFixtureExternalBasketStats';
import { StatsContext } from '@/contexts/stats/context';
import { FixtureBasketStats, PlayerBasketStatistics } from '@/service/types';
import { InfoMessage } from '@/components/common/InfoMessage';
import { COMMON_STRING } from '@/constants/dictionary';
import { StatsTable } from '@/components/StatsTableCells/common';
import { ScoringContext } from '@/contexts/scoring/context';
import { useExternalBasketStatsOnDemand } from '@/service/hooks/useExternalBasketStatsOnDemand';
import { filterExternalBasketStats } from './utils/filterExternalBasketStats';
import { TableHeader } from './TableHeader';
import { ExternalTableRow } from './ExternalTableRow';

export const FixtureExternalBasketTable = () => {
  const {
    activeButtons: { quarter, team },
  } = useContext(StatsContext);

  const { externalCollection, isLoading } = useFixtureExternalBasketStats();
  const { statsOnDemand } = useExternalBasketStatsOnDemand();

  const [collectionStats, setCollectionStats] = useState<FixtureBasketStats[]>(
    externalCollection || [],
  );

  const {
    state: { externalBasketStats },
  } = useContext(ScoringContext);

  useEffect(() => {
    if (!externalBasketStats && !statsOnDemand) return;

    setCollectionStats((prevCollection) => {
      const updatedCollection = prevCollection.filter(
        (stat) =>
          stat.period !== externalBasketStats?.period &&
          stat.period !== statsOnDemand?.period,
      );

      return [...updatedCollection, externalBasketStats, statsOnDemand]
        .filter((item): item is FixtureBasketStats => Boolean(item))
        .sort(
          (a, b) =>
            a.period - b.period ||
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
        );
    });
  }, [externalBasketStats, statsOnDemand]);

  useEffect(() => {
    setCollectionStats(externalCollection || []);
  }, [externalCollection]);

  const externalFilteredData: PlayerBasketStatistics[] | undefined =
    useMemo(() => {
      return filterExternalBasketStats({
        statistics: collectionStats,
        activeQuarter: quarter,
        activeTeam: team,
      });
    }, [collectionStats, quarter, team]);

  if (isLoading)
    return <CircularProgress sx={{ alignSelf: 'center' }} size='2em' />;

  if (!externalFilteredData?.length)
    return (
      <InfoMessage
        message={COMMON_STRING.EXTERNAL_BASKET_STATS_DOES_NOT_EXIST}
      />
    );

  return (
    <TableContainer sx={{ alignItems: 'center' }}>
      <StatsTable>
        <TableHeader />
        <TableBody>
          {externalFilteredData?.length &&
            externalFilteredData?.map((statisticsPerPlayer, index) => (
              <ExternalTableRow
                key={index}
                statisticsPerPlayer={statisticsPerPlayer}
              />
            ))}
        </TableBody>
      </StatsTable>
    </TableContainer>
  );
};
