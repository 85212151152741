import { CircularProgress, Divider, IconButton, Stack } from '@mui/material';
import { useContext } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSnackbar } from 'notistack';
import { QuarterButtons } from '@/components/StatsButtons/QuarterButtons';
import { TypeButtons } from '@/components/StatsButtons/TypeButtons';
import { TeamButtons } from '@/components/StatsButtons/TeamButtons';
import { StatsContext } from '@/contexts/stats/context';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { BUTTON_TYPES, StatsButtonType } from '@/contexts/stats/types';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { COMMON_STRING, SUCCESS_STRING } from '@/constants/dictionary';
import { useExternalBasketStatsOnDemand } from '@/service/hooks/useExternalBasketStatsOnDemand';
import { CustomTooltip } from '../StatsCompareTable/StyledStatsComponents';

export const ExternalBasketStatsButtons = () => {
  const {
    typeButtons,
    changeActiveButton,
    activeButtons: { type },
  } = useContext(StatsContext);
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, mutate, error } = useExternalBasketStatsOnDemand();

  useEffectOnce(() => {
    if (type === STATS_BUTTONS.TYPE.GAME)
      return changeActiveButton(
        typeButtons.find(
          (typeButton) => typeButton.value === STATS_BUTTONS.TYPE.PLAYER,
        ) as StatsButtonType,
        BUTTON_TYPES.TYPE,
      );
  });

  const handleRefresh = async () => {
    await mutate();
    if (error) {
      enqueueSnackbar(error.title, {
        variant: 'error',
        preventDuplicate: true,
      });
    } else {
      enqueueSnackbar(SUCCESS_STRING.EXTERNAL_DATA_SUCCESSFULLY_UPDATED, {
        variant: 'success',
        preventDuplicate: true,
      });
    }
  };

  return (
    <>
      <Stack direction='row' alignItems='center'>
        <QuarterButtons />
        <CustomTooltip title={COMMON_STRING.REFRESH}>
          <span>
            <IconButton
              color='primary'
              onClick={handleRefresh}
              aria-label={ARIA_LABEL.REFRESH_BUTTON}
            >
              {isLoading ? <CircularProgress size='1em' /> : <RefreshIcon />}
            </IconButton>
          </span>
        </CustomTooltip>
      </Stack>

      <>
        <Divider flexItem variant='fullWidth' />
        <Stack direction='row' gap={1}>
          <TypeButtons externalBasketball={true} />
          <Divider flexItem orientation='vertical' />
          <TeamButtons />
        </Stack>
      </>
    </>
  );
};
