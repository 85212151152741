import { TableBody, TableCell, TableRow } from '@mui/material';
import { FC, MouseEvent, useContext } from 'react';
import { FixturesTableRow } from '@/components/FixturesTable/FixturesTableRow';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { FixturesTableContext } from '@/components/FixturesTable/context/FixturesTableContext';
import { Collection } from '@/contexts/fixturesWithIncidents/types';
import { LoadingOverlay } from '@/components/common/LoadingOverlay';
import { InfoMessage } from '@/components/common/InfoMessage';
import { FIXTURE_TABLE_HEADCELLS } from './constants';

export const SCROLL_TO_TOP_OFFSET = 500;

const BODY_HEIGHT_WITH_FILTERS = 312;

const BODY_HEIGHT_WITHOUT_FILTERS = 262;

interface FixturesTableBodyProps {
  onTableClick: (event: MouseEvent<HTMLTableSectionElement>) => void;
  setHoverCollection: (collection: Collection | undefined) => void;
}

export const FixturesTableBody: FC<FixturesTableBodyProps> = ({
  onTableClick,
  setHoverCollection,
}) => {
  const {
    data: { fixturesWithIncidents, isLoading },
    checkboxes: { handleSelectFixture, selectedFixtures },
    filters: { filtersList },
  } = useContext(FixturesTableContext);

  return (
    <TableBody
      onClick={onTableClick}
      aria-label={ARIA_LABEL.ACTIONS_TABLE_BODY}
    >
      {(!fixturesWithIncidents || isLoading) && (
        <LoadingOverlay isLoading={isLoading} />
      )}

      {fixturesWithIncidents &&
        fixturesWithIncidents?.collections.data.map((collection, index) => (
          <FixturesTableRow
            key={collection.collectionId}
            collection={collection}
            selectRow={handleSelectFixture}
            selectedRows={selectedFixtures}
            setHoverCollection={setHoverCollection}
          />
        ))}
      {!fixturesWithIncidents?.collections.pagination.results && !isLoading && (
        <TableRow
          aria-label={ARIA_LABEL.EMPTY_TABLE_ROW}
          sx={{
            height: `calc(100vh - ${
              !!filtersList.length
                ? BODY_HEIGHT_WITH_FILTERS
                : BODY_HEIGHT_WITHOUT_FILTERS
            }px)`,
          }}
        >
          <TableCell
            colSpan={FIXTURE_TABLE_HEADCELLS.length + 3}
            sx={{ textAlign: 'center', border: 'none' }}
          >
            <InfoMessage
              message={`Fixtures with incidents data doesn't exist...`}
            />
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};
