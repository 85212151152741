import { TableRow } from '@mui/material';
import { FC } from 'react';
import { StatsTableStickyDataCell } from '@/components/StatsTableCells/StatsTableCells';
import { PlayerBasketStatistics } from '@/service/types';
import { externalBasketColumnCount } from './constants';
import { StatisticsCell } from './StatisticsCell';

interface ExternalTableRowProps {
  statisticsPerPlayer: PlayerBasketStatistics;
}

export const ExternalTableRow: FC<ExternalTableRowProps> = ({
  statisticsPerPlayer,
}) => (
  <TableRow
    sx={{
      display: 'flex',
      width: '50%',
      '&:hover': {
        background: (theme) => theme.palette.action.hover,
      },
      borderRight: (theme) => `1px solid ${theme.palette.tableBorderColor}`,
    }}
  >
    <StatsTableStickyDataCell
      width={`${100 / externalBasketColumnCount}%`}
      sx={{ overflow: 'hidden' }}
    >
      {`${statisticsPerPlayer.shirtNumber}. ${statisticsPerPlayer.fullName}`}
    </StatsTableStickyDataCell>
    <StatisticsCell
      internalValue={statisticsPerPlayer.internalStatistics.pointsMade}
      externalValue={statisticsPerPlayer.externalStatistics.pointsMade}
    />
    <StatisticsCell
      internalValue={statisticsPerPlayer.internalStatistics.rebounds}
      externalValue={statisticsPerPlayer.externalStatistics.rebounds}
    />
    <StatisticsCell
      internalValue={statisticsPerPlayer.internalStatistics.assists}
      externalValue={statisticsPerPlayer.externalStatistics.assists}
    />
  </TableRow>
);
