import { Divider, Stack } from '@mui/material';
import { useContext } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { QuarterButtons } from '@/components/StatsButtons/QuarterButtons';
import { TypeButtons } from '@/components/StatsButtons/TypeButtons';
import { TeamButtons } from '@/components/StatsButtons/TeamButtons';
import { StatsContext } from '@/contexts/stats/context';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { BUTTON_TYPES, StatsButtonType } from '@/contexts/stats/types';
import { getSport } from '@/service/utils/getSport';
import { SPORT_ID } from '@/service/constants';
import { ScoringContext } from '@/contexts/scoring/context';

export const AdvancedStatsButtons = () => {
  const {
    typeButtons,
    changeActiveButton,
    activeButtons: { type },
  } = useContext(StatsContext);

  const {
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);

  const sportId = getSport(fixtureConfig)?.id;

  const isSnooker = sportId === SPORT_ID.SNOOKER;
  const isAmericanFootball = sportId === SPORT_ID.AMERICAN_FOOTBALL;

  useEffectOnce(() => {
    if (type === STATS_BUTTONS.TYPE.GAME)
      return changeActiveButton(
        typeButtons.find(
          (typeButton) => typeButton.value === STATS_BUTTONS.TYPE.PLAYER,
        ) as StatsButtonType,
        BUTTON_TYPES.TYPE,
      );
  });

  return (
    <>
      <Stack direction='row' gap={1}>
        <QuarterButtons />
      </Stack>
      {!isSnooker && (
        <>
          <Divider flexItem variant='fullWidth' />
          <Stack direction='row' gap={1}>
            <TypeButtons americanFootball={isAmericanFootball} />
            {type !== STATS_BUTTONS.TYPE.TEAM && (
              <>
                <Divider flexItem orientation='vertical' />
                <TeamButtons />
              </>
            )}
          </Stack>
        </>
      )}
    </>
  );
};
