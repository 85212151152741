export const ARIA_LABEL = {
  FIXTURES_TABLE_BODY: 'fixtures-table-body',
  FIXTURES_TABLE_HEAD: 'fixtures-table-head',
  FIXTURES_TABLE_TOOLBAR: 'fixtures-table-toolbar',
  LOADING_OVERLAY: 'loading-overlay',
  RANK_INFO: 'rank-info',
  REFRESH_BUTTON: 'refresh-button',
  STREAMING_BOX: 'streaming-box',
  FIXTURE_SCORE_TEAM_IN_PLAY_COLOR_BOX: 'fixture-score-team-in-play-color-box',
  STATS_COMPARE_TABLE: 'stats-compare-table',
  GAME_STATE: 'game-state',
  PERIOD_SCORE: 'period-score',
  ACTIONS_TABLE: 'actions-table',
  ADVANCED_STATS: 'advanced-stats',
  ADVANCED_STATS_TABLE: 'advanced-stats-table',
  ADVANCED_STATS_BUTTONS: 'advanced-stats-buttons',
  AMERICAN_FOOTBALL_STATS: 'af-stats',
  AMERICAN_FOOTBALL_STATS_BUTTONS: 'AmericanFootballStatsButtons',
  AMERICAN_FOOTBALL_STATS_TABLE: 'AmericanFootballStatsTable',
  BASKETBALL_STATS: 'basketball-stats',
  BASKETBALL_STATS_BUTTONS: 'BasketballStatsButtons',
  BASKETBALL_STATS_TABLE: 'BasketballStatsTable',
  COMPARISON_TABLE: 'ComparisonTable',
  COMPARISON_RATING: 'ComparisonRating',
  SLA_BREACH_EVALUATION: 'SlaBreachFixtureEvaluation',
  SLA_BREACH_ACTION_EVALUATION: 'SlaBreachActionEvaluation',
  COMPARISON_RATING_FORM: 'comparison-rating-form',
  LINEUPS_BOX: 'LineupsBox',
  LINEUPS_TAB_BOX: 'LineupsTabBox',
  LINEUPS_ACTIONS_BOX: 'LineupsActionsBox',
  LINEUPS: 'Lineups',
  SCORESHEET: 'scoresheet-stats',
  SCORESHEET_STATS_BUTTONS: 'ScoresheetStatsButtons',
  SCORESHEET_DATE: 'ScoresheetDate',
  SCORSHEET_SUMMARY: 'ScoresheetSummary',
  POINTS_TABLE: 'PointsTable',
  LEADS_TABLE: 'LeadsTable',
  NO_STREAM: 'NoStream',
  SCORESHEET_LEGEND: 'ScoresheetLegend',
  PLAYERS_TABLE: 'players-table',
  EXTERNAL_STATS: 'external-stats',
  COURT_CANVAS: 'court-canvas',
  EDIT_ACTION: 'edit-action',
  JSON: 'json',
  FIXTURE_STATUS: 'fixture-status',
  COLLECTION_STATUS: 'collection-status',
  COVERAGE_LEVEL: 'coverage-level',
  COMMENT_BUTTONS: 'comment-buttons',
  NAVBAR: 'navbar',
  COMPARISON_BOX: 'comparison-box',
  FIXTURE_INFO: 'fixture-info',
  FIXTURE_MAIN: 'fixture-main',
  FIXTURE_SUMMARY: 'fixture-summary',
  SCORERS_PANEL: 'scorers-panel',
  LINEUP_INDICATOR: 'lineup-indicator',
  STATUSES_SELECT: 'statuses-select',
  COL_ELEMENT: 'col-element',
  TABLE_CONTAINER: 'table-container',
  STATS_TABLE: 'stats-table',
  STATS_TABLE_HEAD: 'stats-table-head',
  QUARTER_BUTTONS: 'quarter-buttons',
  TEAM_BUTTONS: 'team-buttons',
  TYPE_BUTTONS: 'type-buttons',
  STATS_TYPE_SELECT: 'stats-type-select',
  STATS_BUTTON: 'stats-button',
  PLAYER_ROW: 'player-row',
  DID_NOT_PARTICIPATED_PLAYER_ROW: 'did-not-participated-player-row',
  COACH_ROW: 'coach-row',
  TOTALS_ROW: 'totals-row',
  PLAYERS_TABLE_HEADER: 'players-table-header',
  SCORESHEET_TABLE_HEADER_CELL: 'scoresheet-table-header-cell',
  SCORESHEET_TABLE_BODY_ROW: 'scoresheet-table-body-row',
  SOUND_SETTINGS_EDIT: 'sound-settings-edit',
  SIMPLE_INDICATOR: 'simple-indicator',
  PREFERENCES: 'preferences',
  COMPARISON_GRID: 'comparison-grid',
  ADD_RATING: 'add-rating',
  NUMBER_TEXT_FIELD: 'number-text-field',
  COMPARISON_RATING_BUTTONS: 'comparison-rating-buttons',
  COMPARISON_RATING_COMMENT: 'comparison-rating-comment',
  COMPARISON_RATING_SCORE: 'comparison-rating-score',
  COMPARISON_RATING_INFO: 'comparison-rating-info',
  COMPARISON_TABLE_CELL: 'comparison-table-cell',
  COMPARISON_TABLE_STICKY_CELL: 'comparison-table-sticky-cell',
  COMPARISON_TABLE_HEADER: 'comparison-table-header',
  COMPARISON_TABLE_ROW: 'comparison-table-row',
  ACTION_SELECT: 'action-select',
  PLAYERS_SELECT: 'players-select',
  TEAM_SELECT: 'team-select',
  EDIT_BUTTONS: 'edit-buttons',
  SCORESHEET_SCORE: 'scoresheet-score',
  SCORESHEET_DESCRIPTION: 'scoresheet-description',
  STATUS_CHANGE_INFO_WRAPPER: 'status-change-info-wrapper',
  HOVERED_COL: 'hovered-col',
  ACTIONS_TABLE_BODY: 'actions-table-body',
  ACTION_ROW: 'action-row',
  EDIT_ACTION_DIALOG: 'edit-action-dialog',
  ACTION_EVALUATION_DIALOG: 'action-evaluation-dialog',
  SHORTCUT_DIALOG: 'shortcut-dialog',
  FIXTURE_DETAILS_CHIP: 'fixture-details-chip',
  CLOSED_MARKET_INFO: 'closed-market-info',
  ADVANCED_STATS_BASKETBALL_TABLE: 'advanced-stats-basketball-table',
  ADVANCED_STATS_ALL_SPORTS_TABLE: 'advanced-stats-all-sports-table',
  UNSET_RADIO: 'unset-radio',
  UNKNOWN_RADIO: 'unknown-radio',
  GRID_WRAP: 'grid-wrap',
  ACTION_FILTERS_TEMPLATE_FORM: 'action-filters-template-form',
  DISPLAY_TEMPLATE_ACCORDIONS: 'display-template-accordions',
  ADD_TO_TEMPLATE_BUTTON: 'add-to-template-button',
  RESET_TEMPLATE_BUTTON: 'reset-template-button',
  ACTION_FILTERS_FORM: 'action-filters-form',
  ACTION_TYPE_FILTER_CHIP: 'action-type-filter-chip',
  TEMPLATE_NAME: 'template-name',
  EMOJI_PICKER: 'emoji-picker',
  EMOJI: 'emoji',
  EMOJI_FLAG: 'emoji-flag',
  COMMENTS: 'comments',
  TEMPLATE_SWITCHER_ITEM: 'template-switcher-item',
  INPUT_ELEMENT: 'input-element',
  TEXTAREA: 'input-element-textarea',
  DELETE_CONFIRM: 'delete-confirm',
  TEMPLATE_CONFIRMATION_DIALOG: 'template-confirmation-dialog',
  SECTION_TITLE_TYPOGRAPHY: 'section-title-typography',
  INFORMATION_ROW: 'information-row',
  TIME_SINCE_REPORTED: 'time-since-reported',
  RANK_WITH_ICON: 'rank-with-icon',
  SUCCESS_INDICATOR: 'success-indicator',
  AVATAR_CELL: 'avatar-cell',
  FIXTURES_TABLE_ROW_DETAILS: 'fixtures-table-row-details',
  FIXTURES_CHECKBOX: 'fixtures-checkbox',
  FIXTURES_TABLE_ROW: 'fixtures-table-row',
  EMPTY_TABLE_ROW: 'empty-table-row',
  CHECKBOX_ACTION_MENU: 'checkbox-action-menu',
  CHECKBOX_ASSIGN_ME_ITEM: 'checkbox-assign-me-item',
  CHECKBOX_UN_ASSIGN_ME_ITEM: 'checkbox-un-assign-me-item',
  REFRESH_INTERVAL_BUTTON: 'refresh-interval-button',
  AVATAR: 'avatar',
  FIXTURES_TABLE: 'fixtures-table',
  FIXTURES_FILTERS_FORM: 'fixtures-filters-form',
  EXTERNAL_BASKET_STATS: 'external-basket-stats',
  EXTERNAL_BASKETBALL_STATS_BUTTONS: 'external-basket-buttons',
};
